var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-lg-8 col-md-8 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0"
  }, [_vm.preference ? _c('div', {
    staticClass: "row"
  }, [_vm.trust ? _c('div', {
    staticClass: "col-lg-12 col-12 mb-4 pb-2"
  }, [_c('div', {
    staticClass: "card bg-light job-box rounded shadow border-0 overflow-hidden"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('h5', {
    staticClass: "text-center"
  }, [_vm._v(_vm._s(_vm.$t("trust.prop-info")))]), _c('div', {
    staticClass: "row mt-4"
  }, [_c('div', {
    staticClass: "col-md-7"
  }, [_c('div', {
    staticClass: "form-group"
  }, [['Super Admin', 'Staff'].includes(_vm.userRole) ? _c('label', {
    attrs: {
      "for": "property_type"
    }
  }, [_vm._v(_vm._s(_vm.$t("property-type2")) + " ")]) : _vm._e(), ['Client', 'Agent'].includes(_vm.userRole) ? _c('label', {
    attrs: {
      "for": "property_type"
    }
  }, [_vm._v("Institusi ")]) : _vm._e(), this.trust ? _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.moveableProperty.property_type,
      expression: "moveableProperty.property_type"
    }],
    staticClass: "form-control",
    attrs: {
      "name": "",
      "id": "",
      "disabled": _vm.preference && _vm.preference.hibah_fixed_property_type != null
    },
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.moveableProperty, "property_type", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, [_c('option', {
    attrs: {
      "value": "-"
    }
  }, [_vm._v(" -- " + _vm._s(_vm.$t("choose.property-type")) + " -- ")]), _vm._l(_vm.setMoveableArray, function (property) {
    return _c('option', {
      key: property.value,
      domProps: {
        "value": property.value
      }
    }, [_vm._v(" " + _vm._s(property.name) + " ")]);
  })], 2) : _vm._e()]), _vm.preference && ['1', '2'].includes(_vm.trust.hibah_type_id) ? _c('div', {
    staticClass: "form-group"
  }, [['Super Admin', 'Staff'].includes(_vm.userRole) ? _c('label', [_vm._v(" Nama Saham ")]) : _vm._e(), ['Client', 'Agent'].includes(_vm.userRole) ? _c('label', [_vm._v(" Maklumat Akaun ")]) : _vm._e(), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.preference.hibah_fixed_saham_type,
      expression: "preference.hibah_fixed_saham_type"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "name": "hibah_fixed_saham_type",
      "readonly": _vm.preference && ['1', '2'].includes(_vm.trust.hibah_type_id)
    },
    domProps: {
      "value": _vm.preference.hibah_fixed_saham_type
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.preference, "hibah_fixed_saham_type", $event.target.value);
      }
    }
  })]) : _vm._e()])]), _vm.moveableProperty.property_type ? _c('hr', {
    staticClass: "primary"
  }) : _vm._e()]), _vm.moveableProperty.property_type == 'Saham' ? _c('div', {}, [_c('div', {
    staticClass: "card-body"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v(" " + _vm._s(_vm.$t("org-name")) + " ")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.moveableProperty.institution_name,
      expression: "moveableProperty.institution_name"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "name": "",
      "placeholder": _vm.$t('org-name')
    },
    domProps: {
      "value": _vm.moveableProperty.institution_name
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.moveableProperty, "institution_name", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "form-group"
  }, [_vm.trust && ['1', '2'].includes(_vm.trust.hibah_type_id) && _vm.preferenceLabel ? _c('label', [_vm._v(" " + _vm._s(_vm.preferenceLabel) + " ")]) : _vm._e(), !['1', '2'].includes(_vm.trust.hibah_type_id) ? _c('label', [_vm._v(" " + _vm._s(_vm.$t("accmembership-no")) + " ")]) : _vm._e(), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.moveableProperty.account_number,
      expression: "moveableProperty.account_number"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "name": "",
      "placeholder": _vm.preferenceLabel
    },
    domProps: {
      "value": _vm.moveableProperty.account_number
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.moveableProperty, "account_number", $event.target.value);
      }
    }
  })])])]) : _vm._e(), _vm.moveableProperty.property_type == 'Insurance' ? _c('div', {}, [_c('div', {
    staticClass: "card-body"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v(" " + _vm._s(_vm.$t("org-name")) + " ")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.moveableProperty.institution_name,
      expression: "moveableProperty.institution_name"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "name": "",
      "placeholder": _vm.$t('org-name')
    },
    domProps: {
      "value": _vm.moveableProperty.institution_name
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.moveableProperty, "institution_name", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v(" " + _vm._s(_vm.$t("policy-no")) + " ")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.moveableProperty.policy_number,
      expression: "moveableProperty.policy_number"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "name": "",
      "placeholder": _vm.$t('policy-no')
    },
    domProps: {
      "value": _vm.moveableProperty.policy_number
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.moveableProperty, "policy_number", $event.target.value);
      }
    }
  })])])]) : _vm._e(), _vm.moveableProperty.property_type == 'Simpanan' ? _c('div', {}, [_c('div', {
    staticClass: "card-body"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v(" " + _vm._s(_vm.$t("org-name")) + " ")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.moveableProperty.institution_name,
      expression: "moveableProperty.institution_name",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "name": "",
      "placeholder": _vm.$t('org-name')
    },
    domProps: {
      "value": _vm.moveableProperty.institution_name
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.moveableProperty, "institution_name", $event.target.value.trim());
      },
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  })]), _c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v(" " + _vm._s(_vm.$t("accmembership-no")) + " ")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.moveableProperty.account_number,
      expression: "moveableProperty.account_number",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "name": "",
      "placeholder": _vm.$t('accmembership-no')
    },
    domProps: {
      "value": _vm.moveableProperty.account_number
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.moveableProperty, "account_number", $event.target.value.trim());
      },
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  })])])]) : _vm._e(), _vm.moveableProperty.property_type == 'Syarikat' ? _c('div', {}, [_c('div', {
    staticClass: "card-body"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.moveableProperty.institution_name,
      expression: "moveableProperty.institution_name"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "name": "",
      "placeholder": _vm.$t('org-name')
    },
    domProps: {
      "value": _vm.moveableProperty.institution_name
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.moveableProperty, "institution_name", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "form-group"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.moveableProperty.company_registration_number,
      expression: "moveableProperty.company_registration_number"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "name": "",
      "placeholder": _vm.$t('co-regno')
    },
    domProps: {
      "value": _vm.moveableProperty.company_registration_number
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.moveableProperty, "company_registration_number", $event.target.value);
      }
    }
  })])])]) : _vm._e(), _vm.moveableProperty.property_type == 'Kenderaan' ? _c('div', {}, [_c('div', {
    staticClass: "card-body"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('div', {
    staticStyle: {
      "position": "absolute",
      "right": "1%",
      "top": "45.5%"
    },
    attrs: {
      "id": "tooltip-target-1"
    }
  }, [_c('b-icon', {
    attrs: {
      "icon": "exclamation-circle-fill",
      "variant": "secondary"
    }
  }), _c('b-tooltip', {
    attrs: {
      "target": "tooltip-target-1",
      "triggers": "hover",
      "placement": "right"
    }
  }, [_c('label', [_vm._v(" " + _vm._s(_vm.$t("vehicle-types")) + ".")])])], 1), _c('label', [_vm._v(_vm._s(_vm.$t("vehicle-type")))]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.moveableProperty.transport_type,
      expression: "moveableProperty.transport_type"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "name": "",
      "placeholder": _vm.$t('vehicle-type')
    },
    domProps: {
      "value": _vm.moveableProperty.transport_type
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.moveableProperty, "transport_type", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v(" " + _vm._s(_vm.$t("made-in")) + " ")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.moveableProperty.transport_manufacturer,
      expression: "moveableProperty.transport_manufacturer"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "name": "",
      "placeholder": _vm.$t('made-in')
    },
    domProps: {
      "value": _vm.moveableProperty.transport_manufacturer
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.moveableProperty, "transport_manufacturer", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v(" " + _vm._s(_vm.$t("model-name")) + " ")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.moveableProperty.transport_model,
      expression: "moveableProperty.transport_model"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "name": "",
      "placeholder": _vm.$t('model-name')
    },
    domProps: {
      "value": _vm.moveableProperty.transport_model
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.moveableProperty, "transport_model", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v(_vm._s(_vm.$t("org.reg-no")))]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.moveableProperty.transport_registration_number,
      expression: "moveableProperty.transport_registration_number"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "name": "",
      "placeholder": _vm.$t('org.reg-no')
    },
    domProps: {
      "value": _vm.moveableProperty.transport_registration_number
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.moveableProperty, "transport_registration_number", $event.target.value);
      }
    }
  })])])]) : _vm._e(), _vm.moveableProperty.property_type == 'Bank' ? _c('div', {}, [_c('div', {
    staticClass: "card-body"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v(_vm._s(_vm.$t("bank-type")))]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.moveableProperty.bank_type,
      expression: "moveableProperty.bank_type",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "name": ""
    },
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.moveableProperty, "bank_type", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, [_c('option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v("-- " + _vm._s(_vm.$t("choose.bank-type")) + " --")]), _vm._l(_vm.banks, function (item, index) {
    return _c('option', {
      key: index,
      domProps: {
        "value": item.name
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  })], 2)]), _c('div', {
    staticClass: "form-group"
  }, [_c('input', {
    directives: [{
      name: "uppercase",
      rawName: "v-uppercase"
    }, {
      name: "model",
      rawName: "v-model.trim",
      value: _vm.moveableProperty.bank_branch,
      expression: "moveableProperty.bank_branch",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "name": "",
      "placeholder": _vm.$t('bank-branches')
    },
    domProps: {
      "value": _vm.moveableProperty.bank_branch
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.moveableProperty, "bank_branch", $event.target.value.trim());
      },
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  })]), _c('div', {
    staticClass: "form-group"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.moveableProperty.bank_account_no,
      expression: "moveableProperty.bank_account_no",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "name": "",
      "placeholder": _vm.$t('acc-no')
    },
    domProps: {
      "value": _vm.moveableProperty.bank_account_no
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.moveableProperty, "bank_account_no", $event.target.value.trim());
      },
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  })])])]) : _vm._e()])]) : _vm._e()]) : _vm._e(), _c('div', {
    staticClass: "row mt-4"
  }, [_c('div', {
    staticClass: "col-lg-12 text-right"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between"
  }), _c('button', {
    staticClass: "btn btn-primary",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.submitForm
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("save")) + " "), _vm.submit ? _c('span', {
    staticClass: "spinner-border spinner-border-sm"
  }) : _vm._e()])])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }